<template>
  <v-tabs
    :color="($store.state.app.dark) ? 'white' : 'black'"
    class="content-tabs mb-8"
    background-color="transparent"
    :fixed-tabs="$vuetify.breakpoint.smAndUp"
    show-arrows
  >
    <v-tabs-slider></v-tabs-slider>
    <v-tab :to="{ name: 'business.transactions' }" class="no-caps" :ripple="false">
      {{ $t('transactions') }}
      <!-- <v-icon size="24">mdi-format-list-bulleted</v-icon> -->
    </v-tab>
    <v-tab v-if="parseInt($auth.user().role) != 4" :to="{ name: 'business.log-coupon' }" class="no-caps" :ripple="false">
      {{ $t('log_coupon') }}
      <!-- <v-icon size="24">mdi-ticket-account</v-icon> -->
    </v-tab>
    <v-tab :to="{ name: 'business.log-payment' }" class="no-caps" :ripple="false">
      {{ $t('log_payment') }}
      <!-- <v-icon size="24">mdi-currency-usd</v-icon> -->
    </v-tab>
    <v-tab :to="{ name: 'business.log-verify-slip' }" class="no-caps" :ripple="false">
      {{ $t('log_verify_slip') }}
      <!-- <v-icon size="24">mdi-note</v-icon> -->
    </v-tab>
    <v-tab v-if="parseInt($auth.user().role) != 4" :to="{ name: 'business.log-membership-service' }" class="no-caps" :ripple="false">
      {{ $t('log_membership_service') }}
      <!-- <v-icon size="24">mdi-note</v-icon> -->
    </v-tab>
  </v-tabs>
</template>
<script>
  export default {
    data: () => ({
    }),
    methods: {

    }
  }
</script>