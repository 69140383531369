<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
        <MenuBusinessTransactions />
        <data-table
          model="App\Models\History"
        >
          <template v-slot:empty-head>
            <v-icon size="72">mdi-account-tie-outline</v-icon>
          </template>
          <template v-slot:empty-text>
            <!-- <p class="subheading" v-html="$t('staff_description')"></p> -->
          </template>
        </data-table>
      </v-card>
    </v-container>
  </div>
</template>
<script>
// import MenuBusiness from '@/components/ui/MenuBusiness.vue'
import MenuBusinessTransactions from '@/components/ui/MenuBusinessTransactions.vue'
export default {
  components: {
    // MenuBusiness,
    MenuBusinessTransactions
  },
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    loading: true,
    business: null,
    // search: '',
    // itemsPerPageOptions: [10, 25, 50, 75, 100],
    // transactions: []
  }),
  created () {
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))

    // this.axios
    //   .get('/business/settings', { params: { locale: this.$i18n.locale }})
    //   .then(response => {
    //     this.business = response.data.business
    //   })
  
    // this.axios
    //   .get('/business/transactions', { params: { locale: this.$i18n.locale }})
    //   .then(response => {
    //     this.transactions = response.data
    //     this.loading = false
    //   })


  },
  methods: {
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: this.business.currency}).format(number)
    },
    formatDate (dt, format) {
      if (dt === null) {
        dt = '-'
      } else {
        dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
      }
      return dt
    },
  },
  computed: {
    // headers: function () {
    //   return [
    //     {
    //       text: this.$t('customer'),
    //       align: 'left',
    //       sortable: true,
    //       filterable: true,
    //       value: 'customer_number',
    //     },
    //     {
    //       text: this.$t('staff_member'),
    //       align: 'left',
    //       sortable: true,
    //       filterable: true,
    //       value: 'staff_name',
    //     },
    //     {
    //       text: this.$t('event'),
    //       align: 'left',
    //       sortable: true,
    //       filterable: true,
    //       value: 'event',
    //     },
    //     {
    //       text: this.$t('value'),
    //       align: 'left',
    //       sortable: true,
    //       value: 'value',
    //     },
    //     {
    //       text: this.$t('points'),
    //       align: 'left',
    //       sortable: true,
    //       value: 'points',
    //     },
    //     {
    //       text: this.$t('expires'),
    //       align: 'left',
    //       sortable: true,
    //       value: 'expires_at',
    //     },
    //     {
    //       text: this.$t('date'),
    //       align: 'left',
    //       sortable: true,
    //       value: 'created_at',
    //     }
    //   ]
    // }
  }
}
</script>